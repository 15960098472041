<template>
  <main>
    <AdverseReaction />
  </main>
</template>

<script>
import AdverseReaction from "@/components/adverse-reaction/AdverseReaction";

export default {
  name: "AdverseReactionView",
  components: {
    AdverseReaction

  }
};
</script>
<style lang="scss">
@import "@/assets/scss/views/_adverse-reaction";
</style>
